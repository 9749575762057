import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlSsoEnabledService {
  private isSsoEnabled$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  public checkIfSsoEnabled(): void {
    const ssoEnabled = '@sso-enabled';
    // const ssoEnabled = 'false';
    // console.log(ssoEnabled, '@sso-enabled');

    if (this.parseBoolean(ssoEnabled)) {
      this.isSsoEnabled$.next(true);
    }
  }

  parseBoolean(value: string): boolean {
    return value === 'true';
  }

  public isSsoEnabled(): boolean {
    return this.isSsoEnabled$.value;
  }

  public setSsoEnabled(value: boolean): void {
    this.isSsoEnabled$.next(value);
  }

  public setSigla(sigla: string): void {
    localStorage.setItem('siglaMobile', sigla);
  }

  public getSigla(): string {
    return localStorage.getItem('siglaMobile');
  }
}
